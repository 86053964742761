import React, { useState, useEffect } from "react";
import Chart from 'react-apexcharts';
import Datepicker from "react-tailwindcss-datepicker";
import dayjs from 'dayjs';
import { isSameDay, isSameHour, isSameMonth, isSameWeek } from 'date-fns'
import { getReportsData } from "../../api/conversationAPI";
import PartLoading from "../../components/PartLoading"

const Reports = () => {
    const defaultDate = dayjs();

    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState('TOTAL CALLS');
    const [timeRange, setTimeRange] = useState({ from: defaultDate.startOf('day').toDate(), to: defaultDate.toDate() });
    const [difficulty, setDifficulty] = useState("easy");
    const [report, setReport] = useState({
        options: {
            chart: {
                id: 'example-chart',
                zoom: {
                    enabled: false
                },
            },
            xaxis: {
                categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
            },
            yaxis: {
                min: 0
            },
            colors: ['#5029EB'],
        },
        series: [
            {
                name: 'series-1',
                data: [250, 150, 100, 200, 250, 100, 250]
            }
        ],
    })
    const [dateRange, setDateRange] = useState('day')

    const checkSum = (reports) => {
        if (selectedItem === "TOTAL CALLS") return reports.length;
        if (selectedItem === "APPOINTMENTS SET") return reports.reduce((prev, curr) => prev + curr.analysis.appointment, 0)
        if (selectedItem === "OBJECTION HANDLED") {
            return reports.reduce((prev, cur) => prev + cur.analysis.handled, 0);
        }
        if (selectedItem === "OBJECTION NOT HANDLED") {
            return reports.reduce((prev, cur) => prev + cur.analysis.unhandled, 0);
        }
        if (selectedItem === "AVG. CALL DURATION") {
            const totalDuration = reports.reduce((prev, curr) => prev + curr.duration, 0);
            return reports.length > 0 ? (totalDuration / reports.length).toFixed(2) : 0;
        }
        if (selectedItem === "AVG. TALK TIME") {
            const totalRatio = reports.reduce((prev, curr) => prev + curr.analysis.talk_ratio, 0);
            return reports.length > 0 ? `${(totalRatio / reports.length * 100).toFixed(0)}%` : "0%"
        }
        if (selectedItem === "UNSUCCESSFUL CALLS") {
            return reports.reduce((prev, curr) => prev + !curr.analysis.success, 0)
        }
    }

    const reportChartOption = () => {
        if (dateRange === "today" || dateRange === "yesterday") {
            return {
                categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(time => checkSum(reports.filter(rep =>
                    new Date(rep.date).getDate() === time
                )))
            }
        }
        if (dateRange === "thisWeek" || dateRange === "lastWeek") {
            console.log(new Date(reports[0].date))
            return {
                categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
                data: [0, 1, 2, 3, 4, 5, 6].map(day => checkSum(reports.filter(rep => new Date(rep.date).getDay() == day)))
            }
        }
        if (dateRange === "thisYear" || dateRange === "lastYear") {
            return {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => checkSum(reports.filter(rep =>
                    isSameMonth(new Date(rep.date), new Date(new Date().getFullYear(), month, 1))
                )))
            }
        }
        if (dateRange === "thisMonth" || dateRange === "lastMonth") {
            return {
                categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].map(day => checkSum(reports.filter(rep =>
                    isSameDay(new Date(rep.date), new Date(new Date().getFullYear(), new Date().getMonth(), day))
                )))
            }
        }
        if (dateRange === "all") {
            if (reports.length < 2) {
                return {
                    categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                    data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(time => checkSum(reports.filter(rep =>
                        new Date(rep.date).getDate() === time
                    )))
                }
            }
            const firstDate = reports[0].date;
            const lastDate = reports[reports.length - 1].date;
            if (new Date(firstDate).getFullYear() !== new Date(lastDate).getFullYear()) {
                return {
                    categories: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029],
                    data: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029].map(year => checkSum(reports.filter(rep =>
                        new Date(rep.date).getFullYear() === year
                    )))
                }
            }
            if (new Date(firstDate).getMonth() !== new Date(lastDate).getMonth()) {
                return {
                    categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].map(day => checkSum(reports.filter(rep =>
                        isSameDay(new Date(rep.date), new Date(new Date().getFullYear(), new Date().getMonth(), day))
                    )))
                }
            }
            if (new Date(firstDate).getDate() !== new Date(lastDate).getDate()) {
                return {
                    categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].map(day => checkSum(reports.filter(rep =>
                        isSameDay(new Date(rep.date), new Date(new Date().getFullYear(), new Date().getMonth(), day))
                    )))
                }
            }
            if (new Date(firstDate).getDate() === new Date(lastDate).getDate()) {
                return {
                    categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                    data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(time => checkSum(reports.filter(rep =>
                        new Date(rep.date).getDate() === time
                    )))
                }
            }
        }
        return {
            categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(time => checkSum(reports.filter(rep =>
                new Date(rep.date).getDate() === time
            )))
        }
    }

    useEffect(() => {
        const { categories, data } = reportChartOption(timeRange);
        setReport({
            options: {
                chart: {
                    id: 'example-chart',
                    zoom: {
                        enabled: false
                    },
                },
                xaxis: {
                    categories
                },
                yaxis: {
                    min: 0
                },
                colors: ['#5029EB'],
            },
            series: [
                {
                    name: 'series-1',
                    data
                }
            ],
        })
    }, [dateRange, selectedItem, reports, difficulty])
    const reportsData = [
        {
            icon: 'phone',
            title: 'TOTAL CALLS',
            number: reports.length
        },
        {
            icon: 'date_range',
            title: 'APPOINTMENTS SET',
            number: reports.reduce((prev, curr) => prev + curr.analysis.appointment, 0)
        },
        {
            icon: 'adjust',
            title: 'UNSUCCESSFUL CALLS',
            number: reports.reduce((prev, curr) => prev + !curr.analysis.success, 0)
        },
        {
            icon: 'people_outline',
            title: 'CONVERSION RATE',
            number: '27.5%'
        },
        {
            icon: 'alarm',
            title: 'AVG. CALL DURATION',
            number: reports.length > 0 ? (reports.reduce((prev, curr) => prev + curr.duration, 0) / reports.length).toFixed(2) : 0
        },
        {
            icon: 'alarm_on',
            title: 'AVG. TALK TIME',
            number: reports.length > 0 ? `${(reports.reduce((prev, curr) => prev + curr.analysis.talk_ratio, 0) / reports.length * 100).toFixed(0)}%` : "0%"
        },
        {
            icon: 'star_border',
            title: 'OBJECTION HANDLED',
            number: reports.reduce((prev, curr) => prev + curr.analysis.handled, 0)
        },
        {
            icon: 'error_outline',
            title: 'OBJECTION NOT HANDLED',
            number: reports.reduce((prev, curr) => prev + curr.analysis.unhandled, 0)
        },
    ]

    useEffect(() => {
        const getConversations = async () => {
            setLoading(true);
            const reportData = await getReportsData(timeRange, difficulty);
            setLoading(false)
            setReports(reportData);
        }

        getConversations();
    }, [timeRange, difficulty])

    const calculateTimeRange = (value) => {
        const today = dayjs();
        switch (value) {
            case 'today':
                return { from: today.startOf('day').toDate(), to: today.toDate() };
            case 'thisWeek':
                return { from: today.startOf('week').toDate(), to: today.toDate() };
            case 'thisMonth':
                return { from: today.startOf('month').toDate(), to: today.toDate() };
            case 'thisYear':
                return { from: today.startOf('year').toDate(), to: today.toDate() };
            case 'thisQuarter':
                return { from: today.startOf('quarter').toDate(), to: today.toDate() };
            case 'lastQuarter':
                const startOfLastQuarter = today.subtract(1, 'quarter').startOf('quarter').toDate();
                const endOfLastQuarter = today.subtract(1, 'quarter').endOf('quarter').toDate();
                return { from: startOfLastQuarter, to: endOfLastQuarter };
            case 'yesterday':
                return { from: today.subtract(1, 'day').startOf('day').toDate(), to: today.subtract(1, 'day').endOf('day').toDate() };
            case 'lastWeek':
                return { from: today.subtract(1, 'week').startOf('week').toDate(), to: today.subtract(1, 'week').endOf('week').toDate() };
            case 'lastMonth':
                return { from: today.subtract(1, 'month').startOf('month').toDate(), to: today.subtract(1, 'month').endOf('month').toDate() };
            case 'lastMonth':
                return { from: today.subtract(1, 'year').startOf('year').toDate(), to: today.subtract(1, 'year').endOf('year').toDate() };
            case 'all':
                return { from: new Date(2010, 0, 1), to: today.toDate() };
            case 'custom':
                return { from: new Date(2010, 0, 1), to: today.toDate() };
            // Add more cases as needed
            default:
                return { from: today.startOf('day').toDate(), to: today.toDate() };
        }
    };

    const handleTimePeriodChange = (event) => {
        const value = event.target.value;
        setTimeRange(calculateTimeRange(value));
        setDateRange(value)
    };

    const handleDiffcultChange = (e) => {
        const value = e.target.value;
        setDifficulty(value)
    }

    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })

    const handleSelect = (ranges) => {
        console.log(ranges)
        setSelectionRange(ranges)
        setTimeRange({ from: ranges.startDate, to: ranges.endDate })
    }

    return (
        <div className='mb-10 mt-4 rounded-large bg-white h-main-height'>
            {loading ? <PartLoading />
                :
                <div className="w-full h-full gap-5 overflow-auto">
                    <div className="relative flex flex-row justify-end items-start gap-5 mt-8">
                        <select onChange={handleTimePeriodChange} className="flex flex-row justify-between items-center w-32 px-3 py-2 border border-input-border-color rounded-small text-description-font" value={dateRange}>
                            <option className="text-black-font text-sm" value="today">Today</option>
                            <option className="text-black-font text-sm" value="thisWeek">This Week</option>
                            <option className="text-black-font text-sm" value="thisMonth">This Month</option>
                            <option className="text-black-font text-sm" value="thisQuarter">This Quarter</option>
                            <option className="text-black-font text-sm" value="thisYear">This Year</option>
                            <option className="text-black-font text-sm" value="yesterday">Yesterday</option>
                            <option className="text-black-font text-sm" value="lastWeek">Last Week</option>
                            <option className="text-black-font text-sm" value="lastMonth">Last Month</option>
                            <option className="text-black-font text-sm" value="lastQuarter">Last Quarter</option>
                            <option className="text-black-font text-sm" value="lastYear">Last Year</option>
                            <option className="text-black-font text-sm" value="all">All Time</option>
                            <option className="text-black-font text-sm" value="custom">Custom...</option>
                        </select>
                        {dateRange === "custom" && <div className="relative">
                            <Datepicker
                                value={selectionRange}
                                onChange={handleSelect}
                                showShortcuts={true}
                            />
                        </div>}
                        <select onChange={(e) => handleDiffcultChange(e)} className="flex flex-row justify-between items-center w-32 px-3 py-2 border border-input-border-color rounded-small text-description-font" value={difficulty}>
                            <option className="text-black-font text-sm" value="easy">Easy</option>
                            <option className="text-black-font text-sm" value="medium">Medium</option>
                            <option className="text-black-font text-sm" value="hard">Hard</option>
                            <option className="text-black-font text-sm" value="..">All difficulties</option>
                        </select>
                    </div>
                    <div className="grid desktop:grid-cols-4 laptop:grid-cols-3 tablet:grid-cols-2 grid-cols-1 gap-6 my-6 text-start">
                        {reportsData.map((report, index) => (
                            <div key={index} onClick={() => setSelectedItem(report.title)} className={`${selectedItem == report.title ? 'border-b-4 border-[#5029EB]' : ''}`}>
                                <i className="material-icons text-4xl text-toggle-border-uncheck-color">{report.icon}</i>
                                <div className="flex flex-row items-center gap-2 mt-4">
                                    <div className="text-[#060213] font-medium cursor-pointer">{report.title}</div>
                                </div>
                                <div className="text-4xl text-[#060213] font-bold mt-4">{report.number}</div>
                            </div>
                        ))}
                    </div>
                    <div className="w-full !ml-0">
                        <Chart
                            options={report.options}
                            series={report.series}
                            type="area"
                            height={300}
                        />
                    </div>
                </div>}
        </div>
    );
};

export default Reports;
