import './App.css';
import React from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Trainer from './pages/trainer/Trainer';
import Conversations from './pages/conversations/Conversations';
import Reports from './pages/reports/Reports';
import Suggestions from './pages/suggestions/Suggestions';
import Tutorial from './pages/tutorial/Tutorial';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import Feedback from './pages/feedback/Feedback';
import Contact from './pages/contact/Contact';
import Account from './pages/account/Account';
import Password from './pages/password/Password';
import Billing from './pages/billing/Billing';
import DeleteAccount from './pages/delete/DeleteAccount';
import setAuthToken from './utils/setAuthToken';
import "preline/preline";
import PrivateRoute from './routes/PrivateRoute';
import { loadUser } from './api/authAPI';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  const dispatch = useDispatch()

  loadUser(dispatch)

  const routes = [
    { path: "/", element: <SignIn />},
    { path: "/signin", element: <SignIn />},
    { path: "/signup", element: <SignUp />},
    { path: "/trainer", element: <PrivateRoute><Trainer /></PrivateRoute> },
    { path: "/conversations", element: <PrivateRoute><Conversations /></PrivateRoute>},
    { path: "/reports", element: <PrivateRoute><Reports /></PrivateRoute> },
    { path: "/suggestions", element: <PrivateRoute><Suggestions /></PrivateRoute> },
    { path: "/tutorial", element: <PrivateRoute><Tutorial /></PrivateRoute> },
    { path: "/feedback", element: <PrivateRoute><Feedback /></PrivateRoute> },
    { path: "/contact", element: <PrivateRoute><Contact /></PrivateRoute> },
    { path: "/billing", element: <PrivateRoute><Billing /></PrivateRoute> },
    { path: "/account/info", element: <PrivateRoute><Account /></PrivateRoute> },
    { path: "/account/password", element: <PrivateRoute><Password /></PrivateRoute> },
    { path: "/account/delete", element: <PrivateRoute><DeleteAccount /></PrivateRoute> },
  ];

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;