import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../share/sideBar/SideBar";
import Header from "../share/sideBar/Header";
import Loading from "../components/Loading";

const PrivateRoute = ({ children }) => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated)
  if (isAuth == null) return <Loading />
  if (isAuth == false) return <Navigate to='/signin' />

  return (
    <div className='flex flex-row'>
      <SideBar />
      <div className='bg-white h-screen w-full pt-3 pb-3.5 px-8'>
        <Header />
        {children}
      </div>
    </div>
  )
};

export default PrivateRoute;
