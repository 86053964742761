import React from "react";

const PlanModal = ({setShowPlanModal}) => {
    return (
        <div className="fixed z-10 w-screen h-screen bg-black bg-opacity-20 top-0 left-0 flex justify-center items-center">
            <div className="relative w-[460px] rounded-big bg-white">
                <div className="flex w-full justify-end p-4 cursor-pointer" onClick={() => setShowPlanModal(false)}><i className="material-icons text-[#2AA1CD]">clear</i></div>
                <div className="flex flex-col items-center mb-14">
                    <div className="flex justify-center items-center bg-[#FFEAEA] rounded-full p-3"><i className="material-icons text-[#FF0100]">backspace</i></div>
                    <div className="mt-8 text-3xl text-black-font font-medium">Cancel Plan?</div>
                    <div className="mt-2 text-description-font">This can’t be undone</div>
                </div>
                <div className="flex flex-row py-4 px-5 w-full justify-between border-t border-input-border-color gap-2">
                    <div className="w-1/2 py-2 rounded-small border border-input-border-color text-description-font text-sm text-center cursor-pointer">Discard</div>
                    <div className="w-1/2 py-2 rounded-small border bg-[#FF0100] border-input-border-color text-white text-sm text-center cursor-pointer">Cancel</div>
                </div>
            </div>
        </div>
    )
}

export default PlanModal;