import React, { useState, useEffect } from "react";
import Detail from "./component/Detail";
import { getConversations } from "../../api/conversationAPI";
import PartLoading from "../../components/PartLoading";
import { useDispatch } from "react-redux";

const Conversations = () => {
    const [searchInput, setSearchInput] = useState("");
    const [conversations, setConversations] = useState(null);
    const [conversation, setConversation] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const formatDateAndTime = (isoString) => {
        const date = new Date(isoString);

        const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', optionsDate).format(date);

        const optionsTime = { hour: 'numeric', minute: 'numeric' };
        const formattedTime = new Intl.DateTimeFormat('en-US', { ...optionsTime, hour12: true }).format(date);

        return { date: formattedDate, time: formattedTime.toLowerCase() }; // Use lowercase for 'am/pm' 
    };

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}mins ${remainingSeconds}sec`;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const results = await getConversations(dispatch);
                setLoading(false);
                const updatedConversations = results?.map((conversation) => {
                    const { date, time } = formatDateAndTime(conversation.date);
                    const duration = formatDuration(conversation.duration);
                    return { ...conversation, date, time, duration, isChecked: false };
                });
                setConversations(updatedConversations);
            } catch (error) {
                console.error("Failed to fetch conversations:", error);
            }
        };

        fetchData();

    }, []);

    const handleSelectAll = (e) => {
        const checked = e.target.checked;
        setConversations(conversations?.map(conversation => ({ ...conversation, isChecked: checked })));
    };

    const handleCheck = (id) => {
        setConversations(conversations?.map(conversation =>
            conversation._id === id ? { ...conversation, isChecked: !conversation.isChecked } : conversation
        ));
    };

    const showConversationDetail = (id) => {
        const conversationData = conversations.find((conversation) => conversation._id === id)
        setConversation(conversationData);
    }

    return (
        <div className='mb-10 rounded-large bg-white h-main-height'>
            {loading ? <PartLoading />
                :
                <div className="h-full w-full">
                    <div className="flex flex-row mt-8 pl-3 pr-3 py-2 border border-input-border-color w-1/3 rounded-small">
                        <i className="material-icons text-input-font-color">search</i>
                        <input className="focus:outline-none ml-2 text-input-font-color w-[420px] text-sm" type="text" placeholder="Search..." value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                    </div>
                    <div className="flex flex-row mt-8 w-full h-conversations-main-height gap-5">
                        <div className="w-7/12 h-full overflow-auto">
                            <div className="flex flex-row border-b border-b-input-border-color pb-1.5">
                                <input type="checkbox" className="w-1/12" onChange={handleSelectAll} checked={conversations?.every(conversation => conversation.isChecked)} />
                                <div className="text-sm font-medium w-3/12">Name</div>
                                <div className="text-sm font-medium w-2/12">Date</div>
                                <div className="text-sm font-medium w-2/12">Time</div>
                                <div className="text-sm font-medium w-2/12">Call Duration</div>
                                <div className="text-sm font-medium w-2/12"></div>
                            </div>
                            <div className="flex flex-col">
                                {conversations?.filter((conversation) => conversation.name.includes(searchInput))?.map((conversation) => (
                                    <div className="flex flex-row items-center cursor-pointer" key={conversation._id} onClick={() => showConversationDetail(conversation._id)}>
                                        <input
                                            type="checkbox"
                                            className="w-1/12"
                                            checked={conversation.isChecked}
                                            onChange={() => handleCheck(conversation._id)}
                                        />
                                        <div className="text-sm font-medium w-3/12">{conversation.name}</div>
                                        <div className="text-sm w-2/12">{conversation.date}</div>
                                        <div className="text-sm w-2/12">{conversation.time}</div>
                                        <div className="text-sm w-2/12">{conversation.duration}</div>
                                        <div className="flex text-sm w-2/12 py-4">
                                            <div className="flex flex-row px-3 py-1.5 border border-input-border-color rounded-small cursor-pointer">
                                                <i className="material-icons text-background-primary">cloud_download</i>
                                                <div className="text-description-font ml-2">Download</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {conversation == null ? <div className="w-5/12 h-full border border-input-border-color rounded-small">
                            <div className="flex flex-row items-center bg-gray py-4 pl-4 rounded-t-small">
                                <i className="material-icons text-description-font">bubble_chart</i>
                                <div className="text-black-font font-bold ml-2">Call Details</div>
                            </div>
                            <div className="flex flex-row justify-center mt-44">
                                <div className="flex flex-col justify-center items-center max-w-80">
                                    <img src="../conversations.png" width={235} height={235} alt="" />
                                    <div className="text-center mt-4">Select any specific call in the table by the left to see details about the call.</div>
                                </div>
                            </div>
                        </div> :
                            <Detail conversation={conversation} />
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default Conversations;