import React, { useEffect, useState } from "react";
import { getScriptDescription } from "../../../api/scriptAPI";

const ScriptListModal = ({ setScriptDescription, scriptsData, setShowScriptListModal, setScriptID, scriptID, setLoading }) => {

    const [searchInput, setSearchInput] = useState('');
    const [filterItems, setFilterItems] = useState(scriptsData);

    const hanldeScript = async (ID) => {
        setScriptID(ID);
        const res = await getScriptDescription(ID)
        setScriptDescription(res)
        setShowScriptListModal(false);
    }

    useEffect(() => {
        const filterData = scriptsData.filter((item) => item.name.includes(searchInput))
        setFilterItems(filterData)
    }, [searchInput])


    return (
        <div className="fixed z-10 w-screen h-screen bg-black bg-opacity-20 top-0 left-0 flex justify-center items-center">
            <div className="relative w-[700px] rounded-big bg-white">
                <div className="flex flex-row items-center py-6 pl-6">
                    <div className="flex p-4 rounded-full border border-input-border-color"><i className="material-icons text-description-font text-sm">add</i></div>
                    <div className="flex flex-col ml-4">
                        <div className="font-medium">Add Script</div>
                        <div className="text-description-font text-sm">Search for scripts you’ve previously uploaded</div>
                    </div>
                </div>
                <hr className="text-input-border-color"></hr>
                <div className="p-6">
                    <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-1">
                        <i className="material-icons text-input-font-color">search</i>
                        <input className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="text" value={searchInput} onChange={(e) => setSearchInput(e.target.value)}/>
                    </div>
                    <div className="mt-8 max-h-[200px] overflow-auto">
                        {filterItems?.map((script) => (
                            <div className={`flex flex-row p-5 w-full rounded-small bg-white mt-1 hover:border-2 hover:border-background-primary cursor-pointer ${scriptID == script._id ? 'border-2 border-background-primary' : 'border border-input-border-color'}`} key={script._id} onClick={() => setScriptID(script._id)} onDoubleClick={() => hanldeScript(scriptID)}>
                                <img src="../script-item.svg" width={48} height={48} />
                                <div className="flex flex-col ml-4 justify-between">
                                    <div className="font-midbold">{script.name}</div>
                                    <div className="text-midsmall text-description-font font-midbold">{(script.size / (1024 * 1024)).toFixed(2)} MB</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pt-6">
                        <div className="bg-background-primary rounded-small text-white text-center py-4 text-sm font-medium cursor-pointer" onClick={() => hanldeScript(scriptID)}>Add Script +</div>
                    </div>
                    <div className="absolute flex bg-input-border-color p-1 justify-center items-center rounded-full leading-none top-4 right-4 cursor-pointer" onClick={() => setShowScriptListModal(false)}><i className="text-description-font material-icons">clear</i></div>
                </div>
            </div>
        </div>
    )
}

export default ScriptListModal;