import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PlanModal from "./components/PlanModal";
import getStripe from "../../lib/getStripe";

const Billing = () => {
    const user = useSelector(state => state.auth.user);
    const [currentPlan, setCurrentPlan] = useState(user.plan)
    const [showPlanModal, setShowPlanModal] = useState(false);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const result = query.get('result');

        if (result == "success") {
            toast.success("Transaction was successful!");
        }
        if (result == "cancel") {
            toast.warning("Transaction was canceled!");
        }
    }, []);

    async function handleCheckout() {
        const stripe = await getStripe();
        if (stripe) {
            const { error } = await stripe.redirectToCheckout({
                lineItems: [
                    {
                        price: process.env.REACT_APP_STRIPE_PRODUCT_ID,
                        quantity: 1,
                    },
                ],
                mode: "subscription", // here you can specify the mode as either `payment` or `subscription` for one time payments or recurring payments respectively
                successUrl: `${process.env.REACT_APP_RETURN_URL}/billing?result=success`,
                cancelUrl: `${process.env.REACT_APP_RETURN_URL}/billing?result=cancel`,
                customerEmail: user.email,
            });
            console.warn(error.message);
        }
    }

    async function handleCreditCheckout() {
        if (currentPlan === "free") {
            toast.warning("This plan is only for Pro")
            return;
        }
        const stripe = await getStripe();
        if (stripe) {
            const { error } = await stripe.redirectToCheckout({
                lineItems: [
                    {
                        price: process.env.REACT_APP_STRIPE_CREDIT_PRODUCT_ID,
                        quantity: 1,
                    },
                ],
                mode: "payment",
                successUrl: `${process.env.REACT_APP_RETURN_URL}/billing?result=success`,
                cancelUrl: `${process.env.REACT_APP_RETURN_URL}/billing?result=cancel`,
                customerEmail: user.email,
            });
            console.warn(error.message);
        }
    }

    return (
        <div className='mt-16 w-full rounded-large bg-white'>
            <div className="grid laptop:grid-cols-3 tablet:grid-cols-2 grid-cols-1 justify-between p-2 rounded-small bg-gray-light gap-3">
                <div className="relative py-4 px-4 bg-white rounded-small min-h-[360px]">
                    <div className="flex">
                        <div className=""></div>
                        <div className="font-bold">Free plan</div>
                    </div>
                    <div className="text-3xl font-medium mt-3">$0.00<span className="text-sm">/month</span></div>
                    <div className="flex flex-col mt-10">
                        <div className="flex flex-row items-start">
                            <div className="flex justify-center items-center p-1 border border-input-border-color rounded-full"><i className="material-icons text-sm text-[#52CC0C]">check</i></div>
                            <div className="text-description-font font-medium ml-2">Access to all core features</div>
                        </div>
                        <div className="flex flex-row items-start mt-4">
                            <div className="flex justify-center items-center p-1 border border-input-border-color rounded-full"><i className="material-icons text-sm text-[#52CC0C]">check</i></div>
                            <div className="text-description-font font-medium ml-2">15 credits (15 minutes) per month</div>
                        </div>
                    </div>
                    {currentPlan === "free" && (
                        <div className="absolute right-6 top-6 text-sm font-medium rounded-full py-1 px-3 bg-[#9E9FF5]">Your current plan 📍</div>
                    )}
                </div>
                <div className="relative py-4 px-4 bg-white rounded-small min-h-[360px]">
                    <div className="flex">
                        <div className=""></div>
                        <div className="font-bold">Pro</div>
                    </div>
                    <div className="text-3xl font-medium mt-3">$29.99<span className="text-sm">/month</span></div>
                    <div className="flex flex-col mt-10">
                        <div className="flex flex-row items-start">
                            <div className="flex justify-center items-center p-1 border border-input-border-color rounded-full"><i className="material-icons text-sm text-[#52CC0C]">check</i></div>
                            <div className="text-description-font font-medium ml-2">Full access to all features</div>
                        </div>
                        <div className="flex flex-row items-start mt-4">
                            <div className="flex justify-center items-center p-1 border border-input-border-color rounded-full"><i className="material-icons text-sm text-[#52CC0C]">check</i></div>
                            <div className="text-description-font font-medium ml-2">100 credits (100 minutes) per month <span className="text-midsmall">( 1minutes = 1 credit)</span></div>
                        </div>
                        <div className="flex flex-row items-start mt-4">
                            <div className="flex justify-center items-center p-1 border border-input-border-color rounded-full"><i className="material-icons text-sm text-[#52CC0C]">check</i></div>
                            <div className="text-description-font font-medium ml-2">Option to load up on additional credits</div>
                        </div>
                    </div>
                    { currentPlan !== "pro" && <div className="mt-14 w-full rounded-small bg-background-primary text-center py-3.5 text-white text-sm cursor-pointer" onClick={() => handleCheckout()}>Upgrade to Pro</div>}
                    {currentPlan === "pro" && (
                        <div className="absolute right-6 top-6 text-sm font-medium rounded-full py-1 px-3 bg-[#9E9FF5]">Your current plan 📍</div>
                    )}
                </div>
                <div className="relative py-4 px-4 bg-white rounded-small min-h-[360px]">
                    <div className="flex">
                        <div className=""></div>
                        <div className="font-bold">Credits</div>
                    </div>
                    <div className="text-3xl font-medium mt-3">$14.99<span className="text-sm">/100credits</span></div>
                    <div className="flex flex-col mt-10">
                        <div className="flex flex-row items-start">
                            <div className="flex justify-center items-center p-1 border border-input-border-color rounded-full"><i className="material-icons text-sm text-[#52CC0C]">check</i></div>
                            <div className="text-description-font font-medium ml-2">Load up 100 credits (100 minutes) for Pro users only <span className="text-midsmall">( 1minutes = 1 credit)</span></div>
                        </div>
                    </div>
                    <div className="absolute bottom-4 w-full px-4 left-0">
                        <div className="mt-8 w-full rounded-small bg-background-primary text-center py-3.5 text-white text-sm cursor-pointer" onClick={handleCreditCheckout}>Buy Credits</div>
                    </div>
                </div>
            </div>
            <div className="mt-8 pb-8">
                <div className="text-2xl font-bold text-black-font">Next payment date</div>
                <div className="text-sm text-description-font">8th October, 2024</div>
                <div className="text-sm text-description-font border border-input-border-color rounded-small py-2.5 text-center w-40 mt-2 cursor-pointer hover:bg-background-primary hover:text-white" onClick={() => setShowPlanModal(true)}>Cancel plan</div>
            </div>
            {showPlanModal && <PlanModal setShowPlanModal={setShowPlanModal} />}
        </div>
    )
}

export default Billing;