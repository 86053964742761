import React, { useState, useRef } from "react";
import { getScripts, uploadFiles } from "../../../api/scriptAPI";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const UploadScriptModal = ({setShowUploadScript, scripts, setScripts}) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);

    const handleIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFiles((prevFiles) => [...prevFiles, ...Array.from(e.target.files)]);
          }
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };
    
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...Array.from(e.dataTransfer.files)]);
            e.dataTransfer.clearData();
        }
    };
    
    const uploadFilesfunc = async () => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append("files", file);
        });
        const res = await uploadFiles(formData, dispatch);
        toast.success("Upload file successful!")
        setScripts((prevs) => [...prevs, ...res])
        setShowUploadScript(false);
    }

    const handleDeleteFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };
 
    return (
        <div className="fixed z-10 w-screen h-screen bg-black bg-opacity-20 top-0 left-0 flex justify-center items-center">
            <div className="w-[700px] rounded-small bg-white">
                <div className="flex flex-row p-6 border-b border-input-border-color justify-between items-start">
                    <div className="flex flex-row items-center">
                        
                        <div className="flex justify-center items-center p-4 border border-input-border-color rounded-full">
                            <i className="material-icons text-description-font">cloud_upload</i>
                        </div>
                        <div className="ml-2">
                            <div className="text-black-font font-bold">Upload Files</div>
                            <div className="text-description-font text-sm">Add your documents here. You can upload up to 5 files, maximum</div>
                        </div>
                    </div>
                    <div className="bg-input-border-color p-1 justify-center items-center rounded-full leading-none cursor-pointer" onClick={() => setShowUploadScript(false)}><i className="text-description-font material-icons">clear</i></div>
                </div>
                <div className="p-6">
                    <div
                        className={`flex flex-col items-center border ${
                        isDragging ? "border-blue-500" : "border-dashed"
                        } rounded-small border-input-border-color py-14`}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <input
                            type="file"
                            multiple
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        <div className="flex justify-center items-center p-4 border border-input-border-color rounded-full bg-background-primary cursor-pointer" onClick={() => handleIconClick()}>
                            <i className="material-icons text-white">cloud_upload</i>
                        </div>
                        <div className="text-description-font font-medium mt-6">Drag & drop or click here to choose files.</div>
                        <div className="flex flex-row bg-[#FEF3EB] p-2 mt-3 items-center">
                            <i className="material-icons text-[#F17B2C]">error_outline</i>
                            <div className="text-[#F17B2C] text-small font-medium ml-2">Max file size: 10mb</div>
                        </div>
                    </div>
                    <div className="mt-4 max-h-[100px] overflow-auto">
                        {files && files?.map((file, index) => (
                            <div className="flex flex-row items-center justify-between border border-input-border-color rounded-small p-5 w-full mb-3" key={index}>
                                <div className="flex flex-row">
                                    <img src="../script-item.svg"/>
                                    <div className="flex flex-col justify-between ml-3">
                                        <div className="font-medium">{file.name}</div>
                                        <div className="text-small font-medium text-input-font-color">{(file.size / (1024 * 1024)).toFixed(2)} MB</div>
                                    </div>
                                </div>
                                <div className="flex p-3 rounded-full border border-input-border-color cursor-pointer" onClick={() => handleDeleteFile(index)}>
                                    <i className="material-icons text-background-primary">delete</i>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-row justify-center items-center py-3 w-full rounded-small bg-background-primary mt-4 cursor-pointer" onClick={() => uploadFilesfunc()}>
                        <div className="text-white font-medium text-sm mr-3">Upload</div>
                        <i className="material-icons text-white">cloud_upload</i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadScriptModal;