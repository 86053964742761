// src/features/authSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    scriptsData: []
};

const scriptSlice = createSlice({
    name: 'script',
    initialState,
    reducers: {
        addScript(state, action) {
            return {
                ...state,
                scriptsData: action.payload
            }
        },
        updateScript(state, action) {
            console.log(action.payload)
            return {
                ...state,
                scriptsData: [...state.scriptsData, ...action.payload]
            }
        },
    }
});

export const { addScript, updateScript } = scriptSlice.actions;
export const scriptReducer = scriptSlice.reducer;
// export default authReducer;
