import React from "react";

const Loading = () => {
    let circleCommonClasses = 'h-5 w-5 bg-background-primary   rounded-full';

    return (
        <div className='flex w-screen h-screen justify-center items-center'>
            <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
            <div
                className={`${circleCommonClasses} mr-1 animate-bounce200`}
            ></div>
            <div className={`${circleCommonClasses} animate-bounce400`}></div>
        </div>
    );
}

export default Loading;