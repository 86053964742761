import { current } from "@reduxjs/toolkit";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../api/authAPI";
import { toast } from "react-toastify";

const Password = () => {

    const navigate = useNavigate();
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const confirmPasswordRef = useRef(null);
    const currentPasswordRef = useRef(null);

    const hasUppercase = (password) => /[A-Z]/.test(password);
    const hasNumber = (password) => /\d/.test(password);
    const isValidLength = (password) => password.length >= 8;

    const handleChangePassword = async (e) => {
        e.preventDefault();
        const currentPassword = currentPasswordRef.current.value;
        const confirmPassword = confirmPasswordRef.current.value;
        if(confirmPassword !== newPassword) {
            toast.warning("New passwords don't match")
            return;
        }
        const res = await changePassword(currentPassword, confirmPassword);
        if (res.errors) {
            res.errors.map((error) => toast.error(error.msg))
        } else {
            toast.success("Success to change the password!")
        }
    }

    const handleDiscard = () => {
        currentPasswordRef.current.value = "";
        confirmPasswordRef.current.value = "";
        setNewPassword("");
    }

    return (
        <div className='w-full h-main-height mt-9'>
            <div className="w-full border-y border-y-input-border-color">
                <div className="flex flex-row gap-8">
                    <div className="text-sm text-black-font font-medium py-3.5 cursor-pointer" onClick={() => navigate("/account/info")}>Profile Information</div>
                    <div className="text-sm text-description-font font-medium py-3.5 border-b-2 border-background-primary cursor-pointer">Change Password</div>
                    <div className="text-sm text-description-font font-medium py-3.5 cursor-pointer" onClick={() => navigate("/account/delete")}>Delete Account</div>
                </div>
            </div>
            <div className="flex flex-row justify-center w-full mt-24">
                <div className="flex flex-col w-[352px]">
                    <div className="font-bold text-black-font">Change Password</div>
                    <div className="text-description-font text-sm">Update password for enhanced account security.</div>
                    <hr className="text-input-border-color w-full my-4"></hr>
                    <div>
                        <div className="text-sm font-medium">Current Password</div>
                        <div className="flex flex-row items-center px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-1">
                            <i className="material-icons text-input-font-color ml-2">lock_outline</i>
                            <input className="focus:outline-none mx-2 text-input-font-color w-full text-sm" type={showCurrentPassword ? "text" : "password"} ref={currentPasswordRef} />
                            <i className="material-icons text-input-font-color mr-2 cursor-pointer" onClick={() => setShowCurrentPassword(!showCurrentPassword)}>remove_red_eye</i>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="text-sm font-medium">New Password</div>
                        <div className="flex flex-row items-center px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-1">
                            <i className="material-icons text-input-font-color ml-2">lock_outline</i>
                            <input className="focus:outline-none mx-2 text-input-font-color w-full text-sm" type={showNewPassword ? "text" : "password"} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                            <i className="material-icons text-input-font-color mr-2 cursor-pointer" onClick={() => setShowNewPassword(!showNewPassword)}>remove_red_eye</i>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="text-sm font-medium">Confirm New Password</div>
                        <div className="flex flex-row items-center px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-1">
                            <i className="material-icons text-input-font-color ml-2">lock_outline</i>
                            <input className="focus:outline-none mx-2 text-input-font-color w-full text-sm" type={showConfirmPassword ? "text" : "password"} ref={confirmPasswordRef} />
                            <i className="material-icons text-input-font-color mr-2 cursor-pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>remove_red_eye</i>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between gap-2 mt-3">
                        <div className={isValidLength(newPassword) ? "w-1/3 h-1.5 bg-[#38C793]" : "w-1/3 h-1.5 bg-input-border-color"}></div>
                        <div className={hasNumber(newPassword) ? "w-1/3 h-1.5 bg-[#38C793]" : "w-1/3 h-1.5 bg-input-border-color"}></div>
                        <div className={hasUppercase(newPassword) ? "w-1/3 h-1.5 bg-[#38C793]" : "w-1/3 h-1.5 bg-input-border-color"}></div>
                    </div>
                    <div className="text-midsmall text-description-font mt-2">Weak password. Must contain at least;</div>
                    <div className="flex flex-row items-center">
                        <i className={`material-icons text-sm ${hasUppercase(newPassword) ? "text-[#38C793]" : "text-[#868C98]"}`}>{hasUppercase(newPassword) ? "check_circle" : "cancel"}</i>
                        <div className="text-midsmall text-description-font ml-2">At least 1 uppercase</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <i className={`material-icons text-sm ${hasNumber(newPassword) ? "text-[#38C793]" : "text-[#868C98]"}`}>{hasNumber(newPassword) ? "check_circle" : "cancel"}</i>
                        <div className="text-midsmall text-description-font ml-2">At least 1 number</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <i className={`material-icons text-sm ${isValidLength(newPassword) ? "text-[#38C793]" : "text-[#868C98]"}`}>{isValidLength(newPassword) ? "check_circle" : "cancel"}</i>
                        <div className="text-midsmall text-description-font ml-2">At least 8 characters</div>
                    </div>
                    <div className="flex flex-row gap-4 mt-16">
                        <div className="w-1/2 py-2 text-sm text-description-font font-medium text-center border border-input-border-color rounded-small cursor-pointer" onClick={()=> handleDiscard()}>Discard</div>
                        <div className="w-1/2 py-2 text-sm text-white font-medium text-center border border-input-border-color rounded-small bg-background-primary cursor-pointer" onClick={(e) => handleChangePassword(e)}>Apply Changes</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Password;
