import React, { useState, useEffect } from "react";
import WavesurferPlayer from "@wavesurfer/react";
import { useSelector } from "react-redux";

const Detail = ({conversation}) => {
    const user = useSelector(state => state.auth.user);
    const [wavesurfer, setWavesurfer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    
    const onReady = (ws) => {
        setWavesurfer(ws);
        setIsPlaying(false);
        setDuration(ws.getDuration());
        ws.setPlaybackRate(playbackSpeed);
    };

    const onPlayPause = () => {
        if (wavesurfer) {
            wavesurfer.playPause();
            setIsPlaying(!isPlaying);
        }
    };

    const updateCurrentTime = () => {
        if (wavesurfer) {
            setCurrentTime(wavesurfer.getCurrentTime());
        }
    };

    useEffect(() => {
        if (wavesurfer) {
            wavesurfer.on("audioprocess", updateCurrentTime);
            return () => {
                wavesurfer.un("audioprocess", updateCurrentTime);
            };
        }
    }, [wavesurfer]);

    useEffect(() => {
        if (wavesurfer) {
          wavesurfer.setPlaybackRate(playbackSpeed);
        }
      }, [playbackSpeed, wavesurfer]);

    const changePlaybackSpeed = (speed) => {
        setPlaybackSpeed(speed);
    };

    return (
        <div className="relative w-5/12 h-full border border-input-border-color rounded-small">
            <div className="flex flex-row items-center bg-gray py-4 pl-4 rounded-t-small">
                <i className="material-icons text-description-font">bubble_chart</i>
                <div className="text-black-font font-bold ml-2">Call Details</div>
            </div>
            <div className="p-6 h-msg-main-height overflow-auto">
                <div className="flex flex-row justify-between items-start">
                    <div className="flex flex-row items-start">
                        <div className="flex">
                            {user.avatar ? <img className="rounded-full" src={user.avatar} width={64} height={64} alt="avatar" /> : <img className="rounded-full" src="../account-avatar.png" width={64} height={64} alt="avatar" />}
                        </div>
                        <div className="flex flex-col ml-6">
                            <div className="text-2xl text-black-font font-bold">{conversation.name}</div>
                            <div className="text-description-font text-sm">{conversation.date} . {conversation.time}</div>
                            <div className="text-black-font text-midsmall font-medium mt-3">Duration : {conversation.duration}</div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center border border-input-border-color rounded-small px-4 py-1.5 gap-2 cursor-pointer">
                        <div className="text-background-primary text-sm">Download call details</div>
                        <i className="material-icons text-background-primary">cloud_download</i>
                    </div>
                </div>
                <hr className="text-input-border-color mt-8"></hr>
                <div className="flex mt-3">
                    <div className={`flex flex-row p-2 items-center rounded-small gap-1 ${conversation.analysis.appointment ? 'bg-background-primary' : 'bg-gray-light'}`}>
                        <i className={`material-icons ${conversation.analysis.appointment ? 'text-white' : 'text-description-font'} text-midsmall`}>date_range</i>
                        <div className={`text-midsmall ${conversation.analysis.appointment ? 'text-white' : 'text-description-font'}`}>{conversation.analysis.appointment ?"Appointment booked" : "Appointment unbooked"}</div>
                    </div>
                </div>
                <div className="flex flex-row gap-5 justify-between mt-4">
                    <div className="flex flex-row items-center w-1/3">
                        <div className="p-3 rounded-full border border-input-border-color items-center justify-center">
                            <i className="flex justify-center items-center material-icons w-7 h-7 text- text-[#08C112]">check_circle</i>
                        </div>
                        <div className="flex flex-col justify-between ml-2">
                            <div className="text-black-font">{conversation.analysis.handled}</div>
                            <div className="text-midsmall text-input-font-color">Objections handled</div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center w-1/3">
                        <div className="p-3 rounded-full border border-input-border-color items-center justify-center">
                            <i className="flex justify-center items-center material-icons w-7 h-7 text- text-[#EF5724]">error_outline</i>
                        </div>
                        <div className="flex flex-col justify-between ml-2">
                            <div className="text-black-font">{conversation.analysis.unhandled}</div>
                            <div className="text-midsmall text-input-font-color">Objections not handled</div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center w-1/3">
                        <div className="p-3 rounded-full border border-input-border-color items-center justify-center">
                            <i className="flex justify-center items-center material-icons w-7 h-7 text- text-[#35B9E9]">alarm_on</i>
                        </div>
                        <div className="flex flex-col justify-between ml-2">
                            <div className="text-black-font">{conversation.analysis.talk_ratio}</div>
                            <div className="text-midsmall text-input-font-color">Talk Time Ratio</div>
                        </div>
                    </div>
                </div>
                <div className="border border-input-border-color rounded-small overflow-auto mt-8 h-msg-history">
                    {conversation.messages.filter((message) => message.role !== "system").map((message, index) => (
                        message.role !== "bot" ?
                        <div className="pl-3 pr-7 py-3">
                            <div className="flex flex-row gap-1 items-center" key={index}>
                                <div className="material-icons w-8 h-8 bg-avatar-color rounded-small"></div>
                                <div className="flex flex-col">
                                    <div className="font-medium text-sm text-description-font">{user.name}</div>
                                    <div className="text-midsmall text-input-font-color">You</div>
                                </div>
                            </div>
                            <div className="pl-10 pt-3 pr-3">
                                <div className="text-sm text-black-font">{message.message}</div>
                            </div>
                        </div> :
                        <div className="pl-3 pr-7 py-3 border border-input-border-color bg-gray-light" key={index}>
                            <div className="flex flex-row gap-1 items-center">
                                <div className="material-icons w-8 h-8 bg-avatar-color rounded-small"></div>
                                <div className="flex flex-col">
                                    <div className="font-medium text-sm text-description-font">{conversation.name}</div>
                                    <div className="text-midsmall text-input-font-color">AI bot</div>
                                </div>
                            </div>
                            <div className="pl-10 pt-3 pr-3">
                                <div className="text-sm text-black-font">{message.message}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-full mt-8">
                    <div className="relative bg-[#5D5FEF] rounded-big p-4">
                        <div className="flex justify-between text-white">
                            <span className="text-midsmall font-medium">{currentTime.toFixed(2)}s</span>
                            <span className="text-midsmall font-medium">{duration.toFixed(2)}s</span>
                        </div>
                        <WavesurferPlayer
                            height={40}
                            waveColor="#7D7FF2"
                            progressColor="#FFFFFF"
                            url={conversation.record_url}
                            onReady={onReady}
                            playbackSpeed = {playbackSpeed}
                            onPlay={() => setIsPlaying(true)}
                            onPause={() => setIsPlaying(false)}
                        />
                        <div className="flex w-full justify-center">
                            <button onClick={onPlayPause}>
                                {isPlaying ? <div className="flex justify-center items-center bg-white w-8 h-8 rounded-full"><i className="material-icons text-background-primary">pause</i></div> : <div className="flex justify-center items-center bg-white w-8 h-8 rounded-full"><i className="material-icons text-background-primary">play_arrow</i></div>}
                            </button>
                        </div>
                        <div className="absolute mt-2 bottom-4">
                            <div className="flex flex-row items-center gap-2">
                                <button className="text-white text-small font-medium rounded-full px-1 bg-[#7D7FF2]" onClick={() => changePlaybackSpeed(0.25)}>
                                    0.25x
                                </button>
                                <button className="text-white text-small font-medium rounded-full px-1 bg-[#7D7FF2]" onClick={() => changePlaybackSpeed(0.5)}>
                                    0.5x
                                </button>
                                <button className="text-white text-small font-medium rounded-full px-1 bg-[#7D7FF2]" onClick={() => changePlaybackSpeed(1)}>
                                    1x
                                </button>
                                <button className="text-white text-small font-medium rounded-full px-1 bg-[#7D7FF2]" onClick={() => changePlaybackSpeed(1.5)}>
                                    1.5x
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Detail;