import React, { useRef } from "react";
import { contact } from "../../api/contactAPI";

const Contact = () => {

    const titleRef = useRef(null);
    const categoryRef = useRef(null);
    const descriptionRef = useRef(null);

    const handleContact = (e) => {
        e.preventDefault();
        const title = titleRef.current.value;
        const category = categoryRef.current.value;
        const description = descriptionRef.current.value;
        const payload = {title, category, description};
        contact(payload);
    }
 
    return (
        <div className='flex justify-center mb-10 mt-10 rounded-large bg-white w-full'>
            <div className="w-[700px] border border-input-border-color bg-gray-light rounded-small p-8 text-start">
                <div className="mt-6">
                    <div className="text-sm">Title</div>
                    <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-3">
                        <input className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="text" ref={titleRef} />
                    </div>
                </div>
                <div className="mt-6">
                    <div className="text-sm">Category</div>
                    <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-3">
                        <input className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="text" ref={categoryRef} />
                    </div>
                </div>
                <div className="mt-6">
                    <div className="text-sm">Description</div>
                    <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-3">
                        <textarea className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="text" rows={7} ref={descriptionRef} />
                    </div>
                </div>
                <div className="w-full rounded-small text-center py-2.5 mt-20 text-sm text-white font-medium bg-background-primary cursor-pointer" onClick={(e) => handleContact(e)}>Submit</div>
            </div>
        </div>
    );
}

export default Contact;