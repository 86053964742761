import axios from "axios";
import { addConversation } from "../state/conversations";

export const getConversations = async (dispatch) => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_API}/api/conversations`)
    dispatch(addConversation(res.data.conversations))
    return res.data.conversations;
}

export const getConversationByID = async (id) => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_API}/api/conversations/${id}`)
    return res.data.conversation;
}

export const getLastConversation = async (id) => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_API}/api/conversations/${id}`)
    return res.data.conversation;
}

export const getConversationID = async (script_id, call_type, call_level) => {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_API}/api/conversations`, {script_id: script_id, call_type: call_type, call_level: call_level})
    return res;
}

export const getReportsData = async (dateRange, difficulty) => {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_API}/api/conversations/search`, {from: dateRange.from, to: dateRange.to, level: difficulty})
    return res.data.conversations;
}