import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeInfo } from "../../api/authAPI";

const Account = () => {
    const user = useSelector(state => state.auth.user);
    const [selectedImage, setSelectedImage] = useState(null);
    const navigate = useNavigate();
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const [image, setImage] = useState(null)

    const handleImageChange = (e) => {
        const file = (e.target.files[0]);
        setImage(file)
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
        }
    };

    const handleChangeUserInfo = (e) => {
        e.preventDefault();
        const username = nameRef.current.value;
        const email = emailRef.current.value;
        
        const formData = new FormData();
        formData.append("name", username);
        formData.append("email", email)
        if(image) {
            formData.append("file", image);
        }
        changeInfo(formData);
    }

    const handleDiscard = () => {
        setSelectedImage(null);
        nameRef.current.value = user.name;
        emailRef.current.value = user.email;
    }

    return (
        <div className='w-full h-main-height mt-9'>
            <div className="w-full border-y border-y-input-border-color">
                <div className="flex flex-row gap-8">
                    <div className="text-sm text-black-font font-medium py-3.5 border-b-2 border-background-primary cursor-pointer">Profile Information</div>
                    <div className="text-sm text-description-font font-medium py-3.5 cursor-pointer" onClick={() => navigate("/account/password")}>Change Password</div>
                    <div className="text-sm text-description-font font-medium py-3.5 cursor-pointer" onClick={() => navigate("/account/delete")}>Delete Account</div>
                </div>
            </div>
            <div className="flex flex-row justify-center w-full mt-24">
                <div className="flex flex-col w-[352px]">
                    <div className="flex flex-row items-start">
                        <div className="flex w-[64px] h-[64px] rounde-full">
                            {user.avatar ? <img className="rounded-full" src={selectedImage || user.avatar} width={64} height={64} alt="avatar" /> : <img className="rounded-full" src={selectedImage || "../account-avatar.png"} width={64} height={64} alt="avatar" />}
                        </div>
                        <div className="flex flex-col ml-5">
                            <div className="font-bold text-black-font">Profile Image</div>
                            <div className="text-description-font text-sm">Min 400x400px, PNG or JPEG</div>
                            <div className="flex mt-3">
                                <input 
                                    type="file" 
                                    accept="image/png, image/jpeg" 
                                    style={{ display: 'none' }} 
                                    id="upload-button" 
                                    onChange={handleImageChange}
                                />
                                <label htmlFor="upload-button" className="border border-input-border-color text-sm text-description-font rounded-small hover:bg-background-primary hover:text-white py-1 px-2 cursor-pointer">
                                    Upload
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr className="text-input-border-color w-full my-4"></hr>
                    <div>
                        <div className="text-sm font-medium">Your Name</div>
                        <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-1">
                            <input className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="text" ref={nameRef} defaultValue={user?.name} />
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="text-sm font-medium">Email</div>
                        <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-1">
                            <input className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="email" ref={emailRef} defaultValue={user?.email} />
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 mt-16">
                        <div className="w-1/2 py-2 text-sm text-description-font font-medium text-center border border-input-border-color rounded-small cursor-pointer" onClick={() => handleDiscard()}>Discard</div>
                        <div className="w-1/2 py-2 text-sm text-white font-medium text-center border border-input-border-color rounded-small bg-background-primary cursor-pointer" onClick={(e) => handleChangeUserInfo(e)}>Apply Changes</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account;
