import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from '../../api/authAPI';


const SideBar = () => {

    const mainItems = [
        {
            title: 'Lab',
            icon: 'speaker',
            link: '/trainer',
        },
        {
            title: 'Conversations',
            icon: 'call',
            link: '/conversations',
        },
        {
            title: 'Reports',
            icon: 'report',
            link: '/reports',
        },
        {
            title: 'Scripts',
            icon: 'insert_drive_file',
            link: '/suggestions',
        },
    ]

    const otherItems = [
        {
            title: 'Tutorial',
            icon: 'text_fields',
            link: '/tutorial',
        },
        {
            title: 'Feedback',
            icon: 'feedback',
            link: '/feedback',
        },
        {
            title: 'Contact Support',
            icon: 'headset_mic',
            link: '/contact',
        },
        {
            title: 'Billing & Usage',
            icon: 'credit_card',
            link: '/billing',
        },
    ]

    const List = ({main}) => {
        return (
            <div className={`relative flex flex-row px-2.5 py-2 items-center rounded-small mt-2 cursor-pointer ${router.pathname === main.link ? 'bg-hover-color' : 'hover:bg-hover-color'
                }`} onClick={() => navigate(main.link)}>

                <i className={router.pathname === main.link ? "flex justify-center items-center w-5 h-5 material-icons text-background-primary mr-4" : 'flex justify-center items-center w-5 h-5 material-icons text-icon mr-4'}>{main.icon}</i>

                <div className={router.pathname === main.link ? 'font-medium text-white text-sm' : 'font-medium text-sm text-input-font-color'}>
                    {main.title}
                </div>

                {router.pathname === main.link && (
                    <i className="material-icons text-white absolute right-3">keyboard_arrow_right</i>
                )}
            </div>
        )
    }

    const user = useSelector(state => state.auth.user);
    const [userDropDown, setUserDropDown] = useState(false);
    const router = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setUserDropDown(false);
            }
        };

        // Attach the event listener to the document
        document.addEventListener('mousedown', handleOutsideClick);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleUserDropDown = () => {
        setUserDropDown(!userDropDown);
    };

    const handleLogOut = () => {
        localStorage.removeItem('token');
        loadUser(dispatch);
        navigate("/signin");
    }

    // const handleUserDropDown = () => {
    //     setUserDropDown(true)
    // }

    return (
        <div className={`desktop:block desktop:relative hidden absolute z-10 w-64 h-screen bg-primary min-w-64 pl-3.5 pr-6 pt-6`}>
            <div className='relative w-full h-full'>
                <div className="flex flex-row gap-2 mb-20 items-center justify-between">
                    <div className='flex flex-row items-center'>
                        <img className='rounded-small' src='../logo.png' height={40} width={40} />
                        <div className="ml-2">
                            <div className="text-white text-sm font-medium">{user.name}</div>
                            <div className="text-input-font-color font-medium text-midsmall">{user.email}</div>
                        </div>
                    </div>
                    <i className="material-icons text-icon cursor-pointer" onClick={() => handleUserDropDown()}>keyboard_arrow_down</i>
                </div>
                <div className='font-medium text-midsmall text-input-font-color'>MAIN</div>

                {mainItems.map((main) => (<List main={main} key={main.link} />))}

                <hr className="text-icon mt-4"></hr>

                {otherItems.map((main) => <List main={main} key={main.link} />)}

                <div className="absolute bottom-20 px-3 py-4">
                    <div className="bg-gradient-to-r from-[#EBCBFF] to-[#D3D4FF] w-full h-auto bg-no-repeat bg-cover px-3 py-4 rounded-small">
                        <img src="../logo-icon.png" width={39} height={29} alt="Logo" />
                        <div className="font-bold text-2xl text-black-font">Close more deals</div>
                        <div className="font-medium text-description-font leading-5">Increase your close rate and land more listings!</div>
                        <div className='bg-background-primary text-center text-white py-2.5 rounded-small text-midsmall mt-5 cursor-pointer' onClick={() => navigate("/billing")}>Upgrade plan</div>
                    </div>
                </div>
                <div className='absolute bottom-3 flex flex-row justify-center items-center px-5'>
                    <img src='../closer-logo-white.png' />
                </div>

                {userDropDown && <div ref={dropdownRef} className='absolute top-0 pl-3 pr-5 py-4 rounded-small bg-white w-full'>
                    <div className='flex flex-row items-center gap-3 mb-4'>
                        <img className='rounded-small' src='../logo.png' height={40} width={40} />
                        <div className="flex flex-col">
                            <div className="text-black-font font-sm font-medium">{user.name}</div>
                            <div className="text-input-font-color font-medium text-midsmall">{user.email}</div>
                        </div>
                    </div>
                    <hr className='text-gray-light'></hr>
                    <div className='flex flex-row gap-2 items-center mt-4 cursor-pointer' onClick={() => {navigate("/account/info"); setUserDropDown(false)}}>
                        <i className="flex justify-center items-center material-icons text-toggle-border-uncheck-color w-5 h-5">person_outline</i>
                        <div className='text-black-font text-midsmall font-medium'>Account</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center mt-3 cursor-pointer' onClick={() => {navigate("/account/password"); setUserDropDown(false)}}>
                        <i className="flex justify-center items-center material-icons text-toggle-border-uncheck-color w-5 h-5">lock</i>
                        <div className='text-black-font text-midsmall font-medium'>Change password</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center mt-8 cursor-pointer' onClick={() => handleLogOut()}>
                        <i className="flex justify-center items-center material-icons text-[#E83630] w-5 h-5">exit_to_app</i>
                        <div className='text-[#E83630] text-midsmall font-medium'>Log Out</div>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default SideBar;
