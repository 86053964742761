import React, { useState, useEffect } from "react";
import History from "./components/History";
import { useDispatch, useSelector } from "react-redux";
import Vapi from "@vapi-ai/web";
import Agent from "./components/Agent";
import { toast } from "react-toastify";
import ScriptListModal from "./components/ScriptListModal";
import CallScriptModal from "./components/CallScriptModal";
import PartLoading from "../../components/PartLoading";
import { getPrompt, getScripts } from "../../api/scriptAPI";
import { isPublicKeyMissingError } from "../../utils";
import { getConversationID, getLastConversation } from "../../api/conversationAPI";

const vapi = new Vapi(`${process.env.REACT_APP_VAPI_PUBLIC_KEY}`);

const Trainer = () => {
    const dispatch = useDispatch();
    const difficultyData = ["easy", "medium", "hard"];
    const scriptsData = useSelector((state) => state.script.scriptsData);
    const user = useSelector((state) => state.auth.user);
    const [messages, setMessages] = useState([]);
    const [callType, setCallType] = useState("Expired Listing");
    const [showScriptListModal, setShowScriptListModal] = useState(false);
    const [showCallScriptModal, setShowCallScriptModal] = useState(false);
    const [difficultyStatus, setDifficultyStatus] = useState("easy");
    const [callStatus, setCallStatus] = useState("start");
    const [scriptID, setScriptID] = useState(null);
    const [scriptDescription, setScriptDescription] = useState("");
    const [lastConversationID, setLastConversationID] = useState("");
    const [lastConversation, setLastConversation] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleStartCall = async () => {
        const res = await getConversationID(scriptID, callType, difficultyStatus);
        console.log(res)
        if (!res.data.ok) {
            if(res.data.error === "No credit.") {
                toast.error("You have to buy credits.")
            } else {
                toast.error("You have to select the script.");
            }
            return;
        } else {
        setCallStatus("loading");
        setLastConversationID(res.data.conversation._id)
        if (difficultyStatus !== "random") {
            const assistantOverrides = {
                variableValues: {
                    profile: scriptDescription,
                    call_type: callType,
                    call_level: difficultyStatus,
                    conversation_id: res.data.conversation._id
                },
                voice: {
                    provider: "cartesia",
                    voiceId: "3f6e78a8-5283-42aa-b5e7-af82e8bb310c"
                }
            };
            vapi.start(
                process.env.REACT_APP_VAPI_ASSISTANT_ID,
                assistantOverrides
            );
        } else {
            const randomIndex = Math.floor(Math.random() * difficultyData.length);
            const randomStatus = difficultyData[randomIndex];
            const assistantOverrides = {
                variableValues: {
                    profile: scriptDescription,
                    call_type: callType,
                    call_level: randomStatus,
                    conversation_id: res.data.conversation._id
                },
                voice: {
                    provider: "cartesia",
                    voiceId: "3f6e78a8-5283-42aa-b5e7-af82e8bb310c"
                }
            };
            vapi.start(
                process.env.REACT_APP_VAPI_ASSISTANT_ID,
                assistantOverrides
            );
        }}
    }

    const handleEndCall = async () => {
        vapi.stop();
        setLoading(true);
        let checkConversation = setInterval(async () => {
            setLoading(true);
            let lastConversationData = await getLastConversation(lastConversationID);
            if (lastConversationData.record_url) {
                setLoading(false);
                setLastConversation(lastConversationData)
                setShowCallScriptModal(true);
                setCallStatus("start");
                clearInterval(checkConversation);
            }
        }, 1000);
    }

    useEffect(() => {
        getScripts(dispatch);
    }, [loading])

    useEffect(() => {
        vapi.on("call-start", () => {
            setCallStatus("end");

        });

        vapi.on("call-end", () => {
            setCallStatus("start");
        });

        vapi.on("message", (msg) => {
            if (msg.type !== "transcript") return;

            if (msg.transcriptType === "partial") {
            }

            if (msg.transcriptType === "final") {
                setMessages((prev) => {
                    if (prev.length && prev[prev.length - 1].role == msg.role) {
                        if (prev[prev.length - 1].transcript == msg.transcript) return prev;
                        let lastMessage = prev.pop();
                        lastMessage.transcript += msg.transcript;
                        return [prev.slice(0, prev.length - 2), lastMessage];
                    }
                    return [...prev, { role: msg.role, transcript: msg.transcript }]
                })
            }
        });

        vapi.on("speech-start", () => {
        });

        vapi.on("speech-end", () => {
        });

        vapi.on("error", (error) => {
            console.error(error);

            if (isPublicKeyMissingError({ vapiError: error })) {
            }
        });
    }, []);



    return (
        <div className='mb-10 mt-4 rounded-large bg-white h-main-height'>
            {loading ? <PartLoading /> :
                <div className="flex flex-row gap-4 w-full h-full">
                    <History messages={messages} />
                    <Agent setShowScriptListModal={setShowScriptListModal} scriptDescription={scriptDescription} />
                    <div className="relative border border-input-border-color rounded-small text-font-primary w-1/5">
                        <div className="flex flex-row items-center bg-gray-light rounded-t-small py-4 pl-5 gap-3">
                            <i className="material-icons text-description-font">settings</i>
                            <div className="text-black-font font-bold text-center">Call Settings</div>
                        </div>
                        <div className="px-4 mt-6">
                            <div className="font-bold text-input-font-color mb-2">Call type</div>
                            <div className="flex flex-row gap-1 mb-2">
                                <div className="w-4/5">
                                    <div className="font-bold text-black-font">Expired Listing</div>
                                    <div className="font-medium text-sm text-[#7B89A8]">Cold call owners of listings that have expired.</div>
                                </div>
                                <div className="flex justify-end items-start w-1/5">
                                    {callType !== "Expired Listing" ?
                                        <div className="flex flex-row w-8 h-4 bg-input-border-color items-center rounded-full px-0.5" onClick={() => setCallType("Expired Listing")}>
                                            <div className="flex flex-row justify-center items-center w-3.5 h-3.5 bg-white rounded-full">
                                                <div className="w-1 h-1 rounded-full bg-input-border-color"></div>
                                            </div>
                                        </div> :
                                        <div className="flex flex-row w-8 h-4 bg-background-primary justify-end items-center rounded-full px-0.5">
                                            <div className="flex flex-row justify-center items-center w-3.5 h-3.5 bg-white rounded-full">
                                                <div className="w-1 h-1 rounded-full bg-background-primary"></div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-row gap-1 mb-2">
                                <div className="w-4/5">
                                    <div className="font-bold text-black-font">FSBO</div>
                                    <div className="font-medium text-sm text-[#7B89A8]">Cold call owners of properties that are "For Sale By Owner".</div>
                                </div>
                                <div className="flex justify-end items-start w-1/5">
                                    {callType !== "FBSO" ?
                                        <div className="flex flex-row w-8 h-4 bg-input-border-color items-center rounded-full px-0.5" onClick={() => setCallType("FBSO")}>
                                            <div className="flex flex-row justify-center items-center w-3.5 h-3.5 bg-white rounded-full">
                                                <div className="w-1 h-1 rounded-full bg-input-border-color"></div>
                                            </div>
                                        </div> :
                                        <div className="flex flex-row w-8 h-4 bg-background-primary justify-end items-center rounded-full px-0.5">
                                            <div className="flex flex-row justify-center items-center w-3.5 h-3.5 bg-white rounded-full">
                                                <div className="w-1 h-1 rounded-full bg-background-primary"></div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-row gap-1">
                                <div className="w-4/5">
                                    <div className="font-bold text-black-font">GeoLeads</div>
                                    <div className="font-medium text-sm text-[#7B89A8]">Cold call GeoLeads</div>
                                </div>
                                <div className="flex justify-end items-start w-1/5">
                                    {callType !== "GeoLeads" ?
                                        <div className="flex flex-row w-8 h-4 bg-input-border-color items-center rounded-full px-0.5" onClick={() => setCallType("GeoLeads")}>
                                            <div className="flex flex-row justify-center items-center w-3.5 h-3.5 bg-white rounded-full">
                                                <div className="w-1 h-1 rounded-full bg-input-border-color"></div>
                                            </div>
                                        </div> :
                                        <div className="flex flex-row w-8 h-4 bg-background-primary justify-end items-center rounded-full px-0.5">
                                            <div className="flex flex-row justify-center items-center w-3.5 h-3.5 bg-white rounded-full">
                                                <div className="w-1 h-1 rounded-full bg-background-primary"></div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <hr className="mt-8 text-input-border-color"></hr>
                            <div className="font-bold text-input-font-color mt-8">Difficulty</div>
                            <div className="flex flex-col mt-4 gap-3">
                                <div>
                                    <button className={`flex flex-row px-3 py-1.5 items-center border border-input-border-color rounded-small hover:bg-[#38C793] hover:bg-opacity-20 ${difficultyStatus == 'easy' ? 'bg-[#38C793] bg-opacity-20' : ''}`} onClick={() => setDifficultyStatus("easy")}>
                                        <i className="material-icons text-[8px] text-[#38C793]">lens</i>
                                        <div className={`${difficultyStatus == 'easy' ? 'text-[#38C793]' : 'text-description-font'} font-medium ml-2`}>Easy</div>
                                    </button>
                                </div>
                                <div>
                                    <button className={`flex flex-row px-3 py-1.5 items-center border border-input-border-color rounded-small hover:bg-[#F17B2C] hover:bg-opacity-20 ${difficultyStatus == 'medium' ? 'bg-[#F17B2C] bg-opacity-20' : ''}`} onClick={() => setDifficultyStatus("medium")}>
                                        <i className="material-icons text-[8px] text-[#F17B2C]">lens</i>
                                        <div className={`${difficultyStatus == 'medium' ? 'text-[#F17B2C]' : 'text-description-font'} font-medium ml-2`}>Medium</div>
                                    </button>
                                </div>
                                <div>
                                    <button className={`flex flex-row px-3 py-1.5 items-center border border-input-border-color rounded-small hover:bg-[#DF1C41] hover:bg-opacity-20 ${difficultyStatus == 'hard' ? 'bg-[#DF1C41] bg-opacity-20' : ''}`} onClick={() => setDifficultyStatus("hard")}>
                                        <i className="material-icons text-[8px] text-[#DF1C41]">lens</i>
                                        <div className={`${difficultyStatus == 'hard' ? 'text-[#DF1C41]' : 'text-description-font'} font-medium ml-2`}>Hard</div>
                                    </button>
                                </div>
                                <div>
                                    <button className={`flex flex-row px-3 py-1.5 items-center border border-input-border-color rounded-small hover:bg-description-font hover:bg-opacity-20 ${difficultyStatus == 'random' ? 'bg-description-font bg-opacity-20' : ''}`} onClick={() => setDifficultyStatus("random")}>
                                        <i className="material-icons text-[8px] text-description-font">lens</i>
                                        <div className={`${difficultyStatus == 'random' ? 'text-description-font' : 'text-description-font'} font-medium ml-2`}>Random</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {callStatus === 'start' && <div className="absolute bottom-6 flex w-full px-4" onClick={() => handleStartCall()}>
                            <button className=" flex flex-row justify-center items-center gap-2 w-full bg-background-primary text-white font-medium py-2 px-6 rounded-small">
                                <div className="text-sm">Start Call</div>
                                <i className="material-icons text-white text-sm">phone</i>
                            </button>
                        </div>}
                        {callStatus === 'loading' && <div className="absolute bottom-6 flex w-full px-4">
                            <button className=" flex flex-row justify-center items-center gap-2 w-full bg-[#9E9FF5] text-white font-medium py-2 px-6 rounded-small">
                                <div className="text-sm">Call Loading</div>
                                <i className="material-icons text-white text-sm">phone</i>
                            </button>
                        </div>}
                        {callStatus === 'end' && <div className="absolute bottom-6 flex w-full px-4" onClick={() => handleEndCall()}>
                            <button className=" flex flex-row justify-center items-center gap-2 w-full bg-[#FF3E25] text-white font-medium py-2 px-6 rounded-small">
                                <div className="text-sm">End Call</div>
                                <i className="material-icons text-white text-sm">phone</i>
                            </button>
                        </div>}
                    </div>
                </div>
            }
            {showScriptListModal && <ScriptListModal setScriptDescription={setScriptDescription} scriptsData={scriptsData} setShowScriptListModal={setShowScriptListModal} scriptID={scriptID} setScriptID={setScriptID} setLoading={setLoading} />}
            {showCallScriptModal && <CallScriptModal setShowCallScriptModal={setShowCallScriptModal} lastConversation={lastConversation} setMessages={setMessages} />}
        </div>
    );
}

export default Trainer;