// src/features/authSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  expiredeListing: true,
  fsbo: false,
  geoleads: false,
  difficulty: "easy",
  scriptID: null,
};

const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    startCall(state, action) {
        return {
            ...state,
            expiredListing: action.payload.expiredeListing,
            fsbo: action.payload.fsbo,
            geoleads: action.payload.geoleads,
            difficulty: action.payload.difficulty,
            scriptID: action.payload.scriptID
        }
    }
  },
});

export const { startCall } = callSlice.actions;
export const callReducer = callSlice.reducer;
// export default authReducer;
