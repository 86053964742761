import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { loadUser, login } from "../../api/authAPI"
import { loginSuccess } from "../../state/auth";
import { toast } from "react-toastify";

const SignIn = () => {
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

    if (isAuthenticated) {
        return <Navigate to="/trainer" />;
    }

    const handleSignIn = async (e) => {
        e.preventDefault();
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const res = await login({ email, password })
        if (res.errors) {
            res.errors.map((error) => toast.error(error.msg))
        } else {
            toast.success("SignIn Successful!");
            dispatch(loginSuccess(res.data))
            loadUser(dispatch);
            navigate("/trainer");
        }
    }
    return (
        <div className="flex flex-row justify-between py-10 px-20 w-screen h-screen gap-5">
            <div className="flex flex-col justify-start items-center pl-10 w-[529px]">
                <div className="flex flex-col justify-start w-full">
                    <div>
                        <img src="../closer-logo.png" alt="logo" />
                    </div>
                    <div className="mt-12">
                        <div className="text-3xl text-medium font-medium">Sign In</div>
                        <div className="text-description-font font-medium">Enter your credentials to start using CloserLab</div>
                    </div>
                </div>
                <div className="flex flex-col mt-20 w-full gap-y-6">
                    <div className="w-full">
                        <div className="text-black-font font-medium">Email</div>
                        <div className="flex flex-row pl-3 py-3.5 border border-input-border-color w-full rounded-small">
                            <i className="material-icons text-input-font-color">email</i>
                            <input className="focus:outline-none ml-2 text-input-font-color w-[420px]" type="email" placeholder="johndoe@prospect.ai" ref={emailRef} />
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="text-black-font font-medium">Password</div>
                        <div className="flex flex-row pl-3 py-3.5 border border-input-border-color w-full rounded-small">
                            <i className="material-icons text-input-font-color">lock_outline</i>
                            <input className="focus:outline-none ml-2 text-input-font-color w-[420px]" type="password" placeholder="******" ref={passwordRef} />
                        </div>
                    </div>
                </div>
                <div className="w-full py-3.5 text-center text-white bg-background-primary rounded-small mt-14 cursor-pointer" onClick={(e) => handleSignIn(e)}>Login</div>
                <div className="flex w-full justify-start mt-5">
                    <span className="text-description-font font-medium">Don't have an account? </span>
                    <Link to="/signup"><span className="text-background-primary cursor-pointer"> Sign up</span></Link>
                </div>
            </div>
            <div className="w-1/2 h-full">
                <div className="flex flex-row w-full h-32">
                    <div className="w-48 h-full bg-background-primary">
                        <div className="flex items-center justify-center w-full h-full bg-white rounded-br-large">
                            <div className="w-20 h-20 bg-background-primary rounded-large"></div>
                        </div>
                    </div>
                    <div className="w-sign-picture-width bg-background-primary rounded-t-large"></div>
                </div>
                <div className="w-full h-signIn-height rounded-b-large rounded-tl-large bg-background-primary pl-16 pt-16 pr-9">
                    <div className="text-4xl text-white font-medium">Close More Deals with AI-Powered Roleplay</div>
                    <div className="text-white font-medium">Role-play with our simulated AI prospect customized for your offer, and master your cold, warm, and discovery calls.</div>
                    <div className="relative w-full">
                        <img className="absolute h-[480px] w-full bottom--10" src="../auth-content.svg" alt="list" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn