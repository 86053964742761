import React from "react";
import { useSelector } from "react-redux";

const History = ({messages}) => {
    const user = useSelector(state => state.auth.user)
    return (
        <div className="border border-input-border-color rounded-small w-1/5 h-full">
            <div className="flex flex-row items-center bg-gray-light rounded-t-small py-4 pl-5 gap-3">
                <i className="material-icons text-description-font">alarm</i>
                <div className="text-black-font font-bold text-center">Chat History</div>
            </div>
            <div className="h-history-height overflow-auto">
                {messages.map((item, index)=>(
                    item.role == "assistant" ? <div className="pl-3 pr-7 py-3 border border-input-border-color bg-gray-light" key={index}>
                    <div className="flex flex-row gap-1 items-center">
                        <div className="material-icons w-8 h-8 bg-avatar-color rounded-small"></div>
                        <div className="flex flex-col">
                            <div className="font-medium text-sm text-description-font">Porsp</div>
                            <div className="text-midsmall text-input-font-color">AI bot</div>
                        </div>
                    </div>
                    <div className="pl-10 pt-3 pr-3">
                        <div className="text-sm text-black-font">{item.transcript}</div>
                    </div>
                </div> : <div className="pl-3 pr-7 py-3" key={index}>
                    <div className="flex flex-row gap-1 items-center">
                        <div className="material-icons w-8 h-8 bg-avatar-color rounded-small"></div>
                        <div className="flex flex-col">
                            <div className="font-medium text-sm text-description-font">{user.name}</div>
                            <div className="text-midsmall text-input-font-color">You</div>
                        </div>
                    </div>
                    <div className="pl-10 pt-3 pr-3">
                        <div className="text-sm text-black-font">{item.transcript}</div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    )
}

export default History;