import axios from "axios";
import { toast } from "react-toastify";

export const contact = (payload) => {
    axios.post(`${process.env.REACT_APP_BACKEND_API}/api/contacts`, payload)
        .then((res) => {
            if(res.data.ok) {
                console.log(res);
                toast.success("Success send your Contacts!");
            }
        }).catch(err => console.log(err));
}