import React, { useState, useRef } from "react";
import { feedback } from "../../api/feedbackAPI";

const Feedback = () => {
    const motivatedRef = useRef(null);
    const frequentlyRef = useRef(null);
    const fairlyRef = useRef(null);
    const impactedRef = useRef(null);
    const changeRef = useRef(null);
    const experienceRef = useRef(null);
    const [satisfaction, setSatisfaction] = useState(null)
    const [frequentlyRate, setFrequentlyRate] = useState(null)
    const [feeling, setFeeling] = useState(null);
    const frequently = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const handleFeedback = () => {
        const motivated = motivatedRef.current.value;
        const frequently = frequentlyRef.current.value;
        const disappointed = feeling;
        const easy_scale = frequentlyRate;
        const satisfied = satisfaction;
        const impacted = impactedRef.current.value;
        const priced = fairlyRef.current.value;
        const change = changeRef.current.value;
        const experience = experienceRef.current.value;
        const payload = {motivated, frequently, disappointed, easy_scale, satisfied, impacted, priced, change, experience}
        feedback(payload)
    }
    return (
        <div className='flex justify-center mb-10 mt-4 rounded-large bg-white w-full h-feedback-height'>
            <div className="w-1/2 min-w-[400px] h-full border border-input-border-color bg-gray-light rounded-small p-8 text-start overflow-auto">
                <div className="mt-4">
                    <div className="text-sm">What motivated you to start using CloserLab?</div>
                    <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-3">
                        <input className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="text" placeholder="Your answer" ref={motivatedRef}/>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-sm">How frequently do you use CloserLab?</div>
                    <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-3">
                        <input className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="text" placeholder="Your answer" ref={frequentlyRef} />
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-sm">How would you feel if you could no longer use CloserLab?</div>
                    <div class="flex items-center my-4">
                        <input id="feeling-bad" type="radio" value="Very Disappointed" name="disappointed" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onClick={(e) => setFeeling(e.target.value)} />
                        <label for="feeling-bad" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Very Disappointed</label>
                    </div>
                    <div class="flex items-center">
                        <input id="feeling-normal" type="radio" value="Somewhat Disappointed" name="disappointed" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onClick={(e) => setFeeling(e.target.value)} />
                        <label for="feeling-normal" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Somewhat Disappointed</label>
                    </div>
                    <div class="flex items-center my-4">
                        <input id="feeling-good" type="radio" value="Not Disappointed" name="disappointed" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onClick={(e) => setFeeling(e.target.value)} />
                        <label for="feeling-good" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Not Disappointed</label>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-sm">How frequently do you use CloserLab?</div>
                    <div className="flex flex-row items-end justify-between w-full gap-2 mt-4">
                        <div className="text-description-font text-sm w-[100px]">Impossible</div>
                        <div className="flex flex-row justify-between w-full">
                            {frequently.map((fre) => (
                                <div class="flex flex-col items-center justify-center">
                                    <label for={fre} class=" mx-auto text-sm font-medium text-gray-900 dark:text-gray-300">{fre}</label>
                                    <input id={fre} type="radio" value={fre} name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onClick={(e) => setFrequentlyRate(e.target.value)} />
                                </div>
                            ))}
                        </div>
                        <div className="text-description-font text-end text-sm w-[100px]">Too Easy</div>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-sm">How satisfied are you with the overall user experience?</div>
                    <div class="flex items-center my-4">
                        <input id="satisfaction-very-good" type="radio" value="Very Satisfied" name="satisfaction" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onClick={(e) => setSatisfaction(e.target.value)} />
                        <label for="satisfaction-very-good" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Very Satisfied</label>
                    </div>
                    <div class="flex items-center">
                        <input id="satisfaction-good" type="radio" value="Satisfied" name="satisfaction" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onClick={(e) => setSatisfaction(e.target.value)} />
                        <label for="satisfaction-good" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Satisfied</label>
                    </div>
                    <div class="flex items-center my-4">
                        <input id="satisfaction-normal" type="radio" value="Neutral" name="satisfaction" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onClick={(e) => setSatisfaction(e.target.value)} />
                        <label for="satisfaction-normal" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Neutral</label>
                    </div>
                    <div class="flex items-center">
                        <input id="satisfaction-bad" type="radio" value="Unsatisfied" name="satisfaction" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onClick={(e) => setSatisfaction(e.target.value)} />
                        <label for="satisfaction-bad" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Unsatisfied</label>
                    </div>
                    <div class="flex items-center my-4">
                        <input id="satisfaction-very-bad" type="radio" value="Very Unsatisfied" name="satisfaction" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onClick={(e) => setSatisfaction(e.target.value)} />
                        <label for="satisfaction-very-bad" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Very Unsatisfied</label>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-sm">Do you think CloserLab is fairly priced?</div>
                    <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-3">
                        <input className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="text" placeholder="Your answer" ref={fairlyRef} />
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-sm">How has CloserLab impacted your performance/outcomes?</div>
                    <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-3">
                        <input className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="text" placeholder="Your answer" ref={impactedRef} />
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-sm">If you could change one thing about CloserLab, what would it be?</div>
                    <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-3">
                        <input className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="text" placeholder="Your answer" ref={changeRef} />
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-sm">Is there anything else you would like to share about your experience with CloserLab?</div>
                    <div className="flex flex-row px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-3">
                        <input className="focus:outline-none ml-2 text-input-font-color w-full text-sm" type="text" placeholder="Your answer" ref={experienceRef} />
                    </div>
                </div>
                <div className="w-full rounded-small text-center py-2.5 mt-4 text-sm text-white font-medium bg-background-primary cursor-pointer" onClick={() => handleFeedback()}>Submit</div>
            </div>
        </div>
    );
}

export default Feedback;