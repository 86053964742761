// src/features/authSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true;
    },
    loginSuccess(state, action) {
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
        error: null,
      }
    },
    userLoaded(state, action) {
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      };
    },
    deleteUser(state) {
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    }
  },
});

export const { loginStart, loginSuccess, userLoaded, deleteUser } = authSlice.actions;
export const authReducer = authSlice.reducer;
// export default authReducer;
