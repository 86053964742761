import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './auth';
import { callReducer } from './call';
import { scriptReducer } from './scripts';
import { conversationReducer } from './conversations';

const store = configureStore({
  reducer: {
    auth: authReducer,
    call: callReducer,
    script: scriptReducer,
    conversation: conversationReducer,
  },
});

export default store;