import React, { useState, useEffect } from "react";
import moment from "moment";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import UploadScriptModal from "./components/UploadScriptModal";
import { getScripts } from "../../api/scriptAPI";
import { getScriptDescription } from "../../api/scriptAPI";
import ScriptDetail from "./components/ScriptDetail";
import PartLoading from "../../components/PartLoading";
import { useDispatch } from "react-redux";

const Suggestions = () => {
    const dispatch = useDispatch();
    const [showCalendar, setShowCalendar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [scripts, setScripts] = useState([])
    const [search, setSearch] = useState("");
    useEffect(() => {
        const script = async () => {
            setLoading(true);
            const scriptsData = await getScripts(dispatch);
            setLoading(false);
            setScripts(scriptsData);
        }
        script();
    }, [])

    const showUploadDate = () => {
        setShowCalendar(!showCalendar)
    }

    const [scriptDescription, setScriptDescription] = useState("")
    const hanldeScript = async (ID) => {
        setShowScript(true)
        const script = await getScriptDescription(ID)
        setScriptDescription(script)
    }

    const [showUploadScript, setShowUploadScript] = useState(false);
    const [showScript, setShowScript] = useState(false);
    const [value, onChange] = useState(new Date());

    return (
        <div className='mb-10 mt-4 rounded-large bg-white h-main-height'>
            {loading ? <PartLoading /> :
                <>
                    <div className="flex flex-row mt-8 justify-between items-center">
                        <div className="relative flex flex-row gap-6">
                            <div className="flex flex-row pl-3 py-2 border border-input-border-color max-w-[450px] rounded-small">
                                <i className="material-icons text-input-font-color">search</i>
                                <input className="focus:outline-none ml-2 text-input-font-color w-[380px] text-sm" type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
                            </div>
                            <div className="flex flex-row justify-between items-center w-32 px-3 py-2 border border-input-border-color rounded-small cursor-pointer" onClick={() => showUploadDate()}>
                                <div className="text-black-font text-sm">Upload Date</div>
                                <i className="material-icons text-sm text-black-font">keyboard_arrow_down</i>
                            </div>
                            {showCalendar && (<div className="absolute top-10 right-0 z-10"><Calendar onChange={onChange} value={value} /></div>)}
                        </div>
                        <div className="flex flex-row justify-between items-center w-36 px-3 py-2 bg-background-primary rounded-small cursor-pointer" onClick={() => setShowUploadScript(true)}>
                            <div className="text-white text-sm">Upload Script</div>
                            <i className="material-icons text-white">cloud_download</i>
                        </div>
                    </div>
                    <div className="w-full h-suggestion-height mt-12 overflow-auto">
                        <div className="grid desktop:grid-cols-4 laptop:grid-cols-3 tablet:grid-cols-2 grid-cols-1 gap-6 w-full">
                            {scripts.filter((script) => script.name.includes(search))?.map((script) => (
                                <div className="p-2.5 border border-input-border-color rounded-small cursor-pointer" key={script._id} onDoubleClick={() => hanldeScript(script._id)}>
                                    <img className="w-full h-auto" src="../suggestion.png" />
                                    <div className="flex flex-row justify-between items-center pt-4">
                                        <div className="font-medium">{script.name}</div>
                                        <div className="text-input-font-color text-sm">
                                            {moment.utc(script?.createdAt).local().startOf('seconds').fromNow()}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            }
            {showUploadScript && <UploadScriptModal setShowUploadScript={setShowUploadScript} scripts={scripts} setScripts={setScripts} />}
            {showScript && <ScriptDetail setShowScript={setShowScript} scriptDescription={scriptDescription} />}
        </div>
    )
}

export default Suggestions;