import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const router = useLocation();
    const user = useSelector(state => state.auth.user);
    const notifications = [
        {
            link: "/trainer",
            title: "Agent Trainer",
            description: "Practice setting listing appointments with different lead types.",
        },
        {
            link: "/conversations",
            title: "Conversations",
            description: "Deep dive into your call history & call specific metrics",
        },
        {
            link: "/reports",
            title: "Activity Overview",
            description: "Deep dive into your call history & call specific metrics",
        },
        {
            link: "/suggestions",
            title: "Scripts",
            description: "Upload your sales scripts",
        },
        {
            link: "/tutorial",
            title: "Trainer Tutorial",
            description: "If you think we missed anything, let us know in the feedback tab 🙂",
        },
        {
            link: "/feedback",
            title: "Feedback",
            description: "Tear us down, so we can build back better for you 🚀",
        },
        {
            link: "/contact",
            title: "Contact Support",
            description: "Tear us down, so we can build back better for you 🚀",
        },
        {
            link: "/billing",
            title: "Billing & Usage",
            description: "See your different plans",
        },
        {
            link: "/account/info",
            title: "Account",
            description: "See details of your account.",
        },
        {
            link: "/account/password",
            title: "Account",
            description: "See details of your account.",
        },
        {
            link: "/account/delete",
            title: "Account",
            description: "See details of your account.",
        },
    ];

    const currentNotification = notifications.find(
        (notification) => notification.link === router.pathname
    );

    return (
        <div className="flex flex-row justify-between items-center">
            {currentNotification && (
                <div className='flex flex-col'>
                    <div className="font-bold text-2xl text-black-font text-start">{currentNotification.title}</div>
                    <div className='text-description-font text-sm'>{currentNotification.description}</div>
                </div>
            )}
            <div className='flex flex-row items-center gap-5'>
                <div>
                    <div className='flex py-2 px-2.5 rounded-small bg-gray-light'><i className="material-icons !p-0 text-description-font">notifications_none</i></div>
                </div>
                <div className='flex flex-col p-2.5 bg-gray-light rounded-small'>
                    <div className='flex flex-row justify-between'>
                        <div className='text-input-font-color text-[12px]'>Credit Balance:</div>
                        <div className='text-black-font font-bold text-[12px]'>{user.credit} Credits</div>
                    </div>
                    <div className='flex flex-row justify-between gap-5'>
                        <div className='text-input-font-color text-[12px]'>Monthly Credits Remaining:</div>
                        <div className='text-black-font font-bold text-[12px]'>{user.monthly_credit} Credits</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
