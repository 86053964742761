import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signup } from "../../api/authAPI";
import { toast } from "react-toastify";

const SignUp = () => {
    const navigate = useNavigate();
    const emailRef = useRef(null);
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    const handleRegister = async (e) => {
        e.preventDefault();
        const name = usernameRef.current.value;
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const confirmPassword = confirmPasswordRef.current.value;
        if (password !== confirmPassword) {
            toast.error("Password do not match. Please try again.")
            return;
        }
        const res = await signup({name, email, password});
        if (res.errors) {
            res.errors.map((error) => toast.error(error.msg))
        } else {
            toast.success("SignUp Success!");
            localStorage.setItem("token", res.data.token);
            navigate("/trainer");
        }
    };
    return (
        <div className="flex flex-row justify-between py-10 px-20 w-screen h-screen gap-5">
            <div className="flex flex-col justify-start items-center pl-10 w-[529px]">
                <div className="flex flex-col justify-start w-full">
                    <div>
                        <img src="../closer-logo.png" alt="logo" />
                    </div>
                    <div className="mt-12">
                        <div className="text-3xl text-medium font-medium">Get Started Now</div>
                        <div className="text-description-font font-medium">Create your CloserLab account to get started</div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full gap-y-6">
                    <div className="w-full">
                        <div className="text-black-font font-medium">Name</div>
                        <div className="flex flex-row pl-3 py-3.5 border border-input-border-color w-full rounded-small">
                            <i className="material-icons text-input-font-color">person_outline</i>
                            <input className="focus:outline-none ml-2 text-input-font-color w-[420px]" placeholder="John Doe" ref={usernameRef} />
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="text-black-font font-medium">Email</div>
                        <div className="flex flex-row pl-3 py-3.5 border border-input-border-color w-full rounded-small">
                            <i className="material-icons text-input-font-color">email</i>
                            <input className="focus:outline-none ml-2 text-input-font-color w-[420px]" type="email" placeholder="johndoe@prospect.ai" ref={emailRef} />
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="text-black-font font-medium">Password</div>
                        <div className="flex flex-row pl-3 py-3.5 border border-input-border-color w-full rounded-small">
                            <i className="material-icons text-input-font-color">lock_outline</i>
                            <input className="focus:outline-none ml-2 text-input-font-color w-[420px]" type="password" placeholder="******" ref={passwordRef}  />
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="text-black-font font-medium">Confirm Password</div>
                        <div className="flex flex-row pl-3 py-3.5 border border-input-border-color w-full rounded-small">
                            <i className="material-icons text-input-font-color">lock_outline</i>
                            <input className="focus:outline-none ml-2 text-input-font-color w-[420px]" type="password" placeholder="******" ref={confirmPasswordRef} />
                        </div>
                    </div>
                </div>
                <div className="w-full py-3.5 text-center text-white bg-background-primary rounded-small mt-14 cursor-pointer" onClick={e => handleRegister(e)}>Create account</div>
                <div className="flex w-full justify-start mt-5">
                    <span className="text-description-font font-medium">Areadly have an account? </span>
                    <Link to="/signin"><span className="text-background-primary cursor-pointer">Login</span></Link>
                </div>
            </div>
            <div className="w-1/2 h-full">
                <div className="flex flex-row w-full h-32">
                    <div className="w-48 h-full bg-background-primary">
                        <div className="flex items-center justify-center w-full h-full bg-white rounded-br-large">
                            <div className="w-20 h-20 bg-background-primary rounded-large"></div>
                        </div>
                    </div>
                    <div className="w-sign-picture-width bg-background-primary rounded-t-large"></div>
                </div>
                <div className="w-full h-signIn-height rounded-b-large rounded-tl-large bg-background-primary pl-16 pt-16 pr-9">
                    <div className="text-4xl text-white font-medium">Land More Listings with AI-Powered Roleplay</div>
                    <div className="text-white font-medium">Practice Setting Listing Appointments With Our Simulated AI Prospect, Customized for FSBO, Expired Listings, and GeoLeads.</div>
                    <div className="relative w-full">
                        <img className="absolute h-[480px] w-full bottom--10" src="../auth-content.svg" alt="list"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp;