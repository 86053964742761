import React, { useState } from "react";

const ScriptDetail = ({ setShowScript, scriptDescription }) => {


    return (
        <div className="fixed z-10 w-screen h-screen bg-black bg-opacity-20 top-0 left-0 flex justify-center items-center">
            <div className="w-[700px] h-1/2 rounded-small bg-white">
                <div className="flex flex-row bg-gray-light items-center px-5 justify-between rounded-t-small">
                    <div className="flex flex-row items-center py-4 gap-3">
                        <i className="material-icons text-description-font">people_outline</i>
                        <div className="text-black-font font-bold text-center">Description</div>
                    </div>
                    <div className="bg-input-border-color p-1 justify-center items-center rounded-full leading-none cursor-pointer" onClick={() => setShowScript(false)}><i className="text-description-font material-icons">clear</i></div>
                </div>
                <div className="text-description-font h-script-height px-6 py-6 overflow-auto">
                    {scriptDescription}
                </div>
            </div>
        </div>
    )
}

export default ScriptDetail;