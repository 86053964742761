// src/features/authSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    conversationsData: []
};

const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        addConversation(state, action) {
            return {
                ...state,
                conversationsData: action.payload
            }
        },
        updateConversation(state, action) {
            return {
                ...state,
                conversationsData: [...state.conversationsData, action.payload]
            }
        },
    }
});

export const { addConversation, updateConversation } = conversationSlice.actions;
export const conversationReducer = conversationSlice.reducer;
// export default ConversationReducer;
