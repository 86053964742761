import { loadStripe, Stripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');
  }
  return stripePromise;
};

export default getStripe;