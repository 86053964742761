import React, { useState, useEffect } from "react";

const Tutorial = () => {

    return (
        <div className='mb-10 mt-4 rounded-large bg-white h-main-height'>
            <video className="w-full" src=""></video>
        </div>
    )
}

export default Tutorial;