import axios from "axios";
import { deleteUser, loginSuccess, userLoaded } from '../state/auth';
import setAuthToken from '../utils/setAuthToken';
import { toast } from "react-toastify";
import { updateScript } from "../state/scripts";


export const login = async (payload) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_API}/api/auth`, payload)
        .then((res) => {
            if(res.status == 200) {
                return res;
            }
        }).catch((err) => {
            console.log(err);
            return err.response.data
        })
    return res
}

export const signup = async (payload) => {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_API}/api/users`, payload)
        .then((res) => {
            if(res.status == 200) {
                return res
            }
        }).catch(err => {console.log(err); return err.response.data});
    return res
}

export const loadUser = (dispatch) => {
    setAuthToken(localStorage.token);
    axios.get(`${process.env.REACT_APP_BACKEND_API}/api/auth`)
        .then((res) => {
            if(res.status == 200) {
                dispatch(userLoaded(res.data));
            }
        }).catch(err =>{
            console.log(err);
            dispatch(deleteUser());
        });
}

export const changePassword = async (currentPassword, newPassword) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_API}/api/users/change-password`, {password: currentPassword, newpassword: newPassword})
        .then((res) => {
            if(res.data.ok) {
                return true;
            }
        }).catch(err => {
            console.log(err);
            return err.response.data;
        })
    return res
}

export const changeInfo = (formData) => {
    axios.post(`${process.env.REACT_APP_BACKEND_API}/api/users/change-profile`, formData)
        .then((res) => {
            console.log(res)
            if(res.data.ok) {
                toast.success("Success uploading your personal info!")
            }
        }).catch(err => console.log(err))
}

