import React from "react";

const PartLoading = () => {
    return (
        <div className='flex w-full h-full justify-center items-center z-index-20'>
            <div className={`h-5 w-5 bg-background-primary rounded-full mr-1 animate-bounce`}></div>
            <div
                className={`h-5 w-5 bg-background-primary rounded-full mr-1 animate-bounce200`}
            ></div>
            <div className={`h-5 w-5 bg-background-primary rounded-full animate-bounce400`}></div>
        </div>
    )
}

export default PartLoading;