import axios from "axios";
import { addScript, updateScript } from "../state/scripts";

export const uploadFiles = async (payload, dispatch) => {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_API}/api/scripts/upload`, payload)
        .then((res) => {
            if(res.data.ok) {
                dispatch(updateScript(res.data.scripts))
                return res.data.scripts
            }
        }).catch(err => console.log(err));
        return res;
}

export const getScripts = async (dispatch) => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_API}/api/scripts`)
            .then(res => {
                dispatch(addScript(res.data.scripts))
                return res.data.scripts
            })
            .catch((err) => console.log(err));
            return res
}

export const getScriptDescription = async (id) => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_API}/api/scripts/${id}`)
    console.log(res)
    return res.data.script
}

export const getPrompt = async (id, type, difficulty) => {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_API}/prompt`, {script_id: id, call_type: type, difficult_level: difficulty})
    return res.data;
}