import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

const DeleteAccount = () => {

    const navigate = useNavigate();
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return (
        <div className='w-full h-main-height mt-9'>
            <div className="w-full border-y border-y-input-border-color">
                <div className="flex flex-row gap-8">
                    <div className="text-sm text-black-font font-medium py-3.5 cursor-pointer" onClick={() => navigate("/account/info")}>Profile Information</div>
                    <div className="text-sm text-description-font font-medium py-3.5 cursor-pointer" onClick={() => navigate("/account/password")}>Change Password</div>
                    <div className="text-sm text-description-font font-medium py-3.5 border-b-2 border-background-primary cursor-pointer">Delete Account</div>
                </div>
            </div>
            <div className="flex flex-row justify-center w-full mt-24">
                <div className="flex flex-col w-[352px]">
                    <div className="font-bold text-black-font">Delete Account</div>
                    <div className="text-description-font text-sm">Manage the process of deleting account.</div>
                    <hr className="text-input-border-color w-full my-4"></hr>
                    <div className="text-description-font text-sm">All of your data, including your profile, and personal information, will be permanently removed </div>
                    <div className="mt-3 text-description-font text-sm">By entering your password, you confirm that you understand and accept the consequences of deleting your account.</div>
                    <hr className="text-input-border-color w-full my-4"></hr>
                    <div className="">
                        <div className="text-sm font-medium">Confirm Deletion</div>
                        <div className="flex flex-row items-center px-1 py-2 border border-input-border-color w-full rounded-small bg-white mt-1">
                            <i className="material-icons text-input-font-color ml-2">lock_outline</i>
                            <input className="focus:outline-none mx-2 text-input-font-color w-full text-sm" type={showConfirmPassword ? "text" : "password"} />
                            <i className="material-icons text-input-font-color mr-2 cursor-pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>remove_red_eye</i>
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 mt-16">
                        <div className="w-1/2 py-2 text-sm text-description-font font-medium text-center border border-input-border-color rounded-small cursor-pointer">Discard</div>
                        <div className="w-1/2 py-2 text-sm text-white font-medium text-center border border-input-border-color rounded-small bg-[#DF1C41] cursor-pointer">Delete Account</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteAccount;