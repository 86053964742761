import axios from "axios";
import { toast } from "react-toastify";

export const feedback = (payload) => {
    axios.post(`${process.env.REACT_APP_BACKEND_API}/api/feedbacks`, payload)
        .then((res) => {
            if(res.data.ok) {
                toast.success("Success send your Feedback!");
            }
        }).catch(err => console.log(err));
}